import React,{Component} from 'react';
import {ServicesGallerySection,HeadingLayout,GalleryWrapper,GalleryHeading,ImageHolder,
    NextPrev,PrevImg,NextImg
} from './servicesgallery.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ServicesGallery extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }
    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }
    
    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };
        return (
            <ServicesGallerySection>
                <Container>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        <GalleryWrapper>
                            <Row>
                                <Col md={12}>
                                    <HeadingLayout>
                                        <GalleryHeading>
                                            {this.props.services2Json.ServicesGallery.GalleryHeading}
                                        </GalleryHeading>
                                    </HeadingLayout>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,0)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image1.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,1)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image2.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,2)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image3.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,3)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image4.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,4)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image5.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,5)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image6.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                            </Row>
                        </GalleryWrapper>
                        <GalleryWrapper>
                            <Row>
                                <Col md={12}>
                                    <HeadingLayout>
                                        <GalleryHeading>
                                            {this.props.services2Json.ServicesGallery.GalleryHeading}
                                        </GalleryHeading>
                                    </HeadingLayout>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,6)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image7.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,7)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image8.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,8)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image9.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,9)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image10.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,10)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image11.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                                <Col md={4}>
                                    <ImageHolder onClick={this.openDialogBox.bind(this,this.props.services2Json.ServicesGallery,11)}>
                                        <GatsImg 
                                                    fluid={this.props.services2Json.ServicesGallery.Image12.childImageSharp.fluid} 
                                                    alt=""
                                                />
                                    </ImageHolder>
                                </Col>
                            </Row>
                        </GalleryWrapper>
                    </Slider>
                    <NextPrev>
                        <button aria-label="Prev Button" onClick={this.previous}>
                            <PrevImg src={this.props.services2Json.ServicesGallery.PrevImg} alt=""/>
                        </button>
                        <button aria-label="Next Button" onClick={this.next}>
                            <NextImg src={this.props.services2Json.ServicesGallery.NextImg} alt=""/>
                        </button>
                    </NextPrev>
                </Container>
            </ServicesGallerySection>
        );
    }
}

export default props => (
<StaticQuery
    query={graphql`
        query {
            services2Json{
                ServicesGallery{
                    GalleryHeading
                    PrevImg
                    NextImg
                    Image1{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image2{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image3{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image4{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image5{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image6{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image7{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image8{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image9{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image10{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image11{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    Image12{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `}
    render={(data) => (
        <ServicesGallery 
        services2Json={data.services2Json}
        {...props}
        />
    )}
/>
)
