import React from 'react';
import {Container} from 'react-bootstrap';
import {
    ClientSection,
    ClientLogoLayout,
    ClientLogoHolder,
    ClientLogo
} from './clients.style';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Clients = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        services2Json{
            Clients{
                ClientLogoDetails{
                    ClientLogo{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
  `);

    return(
        <ClientSection>
            <Container>
                <ClientLogoLayout>
                    <ClientLogoHolder><ClientLogo>
                        <GatsImg 
                            fluid={JSONData.services2Json.Clients.ClientLogoDetails[0].ClientLogo.childImageSharp.fluid} 
                            alt=""
                            
                        />
                    </ClientLogo></ClientLogoHolder>
                    <ClientLogoHolder><ClientLogo>
                        <GatsImg 
                            fluid={JSONData.services2Json.Clients.ClientLogoDetails[1].ClientLogo.childImageSharp.fluid} 
                            alt=""
                            
                        />
                    </ClientLogo></ClientLogoHolder>
                    <ClientLogoHolder><ClientLogo>
                        <GatsImg 
                            fluid={JSONData.services2Json.Clients.ClientLogoDetails[2].ClientLogo.childImageSharp.fluid} 
                            alt=""
                            
                        />
                    </ClientLogo></ClientLogoHolder>
                    <ClientLogoHolder><ClientLogo>
                        <GatsImg 
                            fluid={JSONData.services2Json.Clients.ClientLogoDetails[3].ClientLogo.childImageSharp.fluid} 
                            alt=""
                            
                        />
                    </ClientLogo></ClientLogoHolder>
                    <ClientLogoHolder><ClientLogo>
                        <GatsImg 
                            fluid={JSONData.services2Json.Clients.ClientLogoDetails[4].ClientLogo.childImageSharp.fluid} 
                            alt=""
                        />
                    </ClientLogo></ClientLogoHolder>
                </ClientLogoLayout>
            </Container>
        </ClientSection>
    );
}

export default Clients;