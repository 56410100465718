import React from 'react';
import {OurWorkSection,HeadingLayout,OurWorkHeading,ImageHolder
} from './ourwork.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactCompareImage from 'react-compare-image';
import { useStaticQuery, graphql } from 'gatsby';

const OurWork  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        services2Json{
            OurWork{
                OurWorkHeading
                Image1
                Image2
                Image3
                Image4
                LeftImageLabel
                RightImageLabel
            }
        }
    }
  `);

    return (
        <OurWorkSection>
            <Container>
                <Row>
                    <Col md={12}>
                    <HeadingLayout>
                        <OurWorkHeading>
                            {JSONData.services2Json.OurWork.OurWorkHeading}
                        </OurWorkHeading>
                    </HeadingLayout>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <ImageHolder>
                            <ReactCompareImage 
                                leftImage={JSONData.services2Json.OurWork.Image1} 
                                leftImageLabel={JSONData.services2Json.OurWork.LeftImageLabel} 
                                rightImage={JSONData.services2Json.OurWork.Image2} 
                                rightImageLabel={JSONData.services2Json.OurWork.RightImageLabel} 
                            />
                        </ImageHolder>
                    </Col>
                    <Col md={6}>
                        <ImageHolder>
                            <ReactCompareImage 
                                leftImage={JSONData.services2Json.OurWork.Image3} 
                                leftImageLabel={JSONData.services2Json.OurWork.LeftImageLabel} 
                                rightImage={JSONData.services2Json.OurWork.Image4} 
                                rightImageLabel={JSONData.services2Json.OurWork.RightImageLabel} 
                            />
                        </ImageHolder>
                    </Col>
                </Row>
            </Container>

        </OurWorkSection>
    );
}
export default OurWork;