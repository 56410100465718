import React from 'react';
import {WhatWeDoSection,WhatWeDoInner,
    WhatWeDoLeft,WhatWeDoRight,WhatWeDoLeftHeading,WhatWeDoLeftPara,WhatWeDoRightImgHolder,
    WhatWeDoRow
} from './whatwedo.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const WhatWeDo  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        services2Json{
            WhatWeDo{
                WhatWeDoRightImg{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                WhatWeDoLeftHeading
                WhatWeDoLeftPara1
                WhatWeDoLeftPara2
            }
        }
    }
  `);

    return (
        <WhatWeDoSection id="WhatWeDoContainer">
            <Container>
                <WhatWeDoInner>
                    <WhatWeDoRow>
                        <Col md="6">
                            <WhatWeDoRight>
                                <WhatWeDoRightImgHolder>
                                    <GatsImg 
                                        fluid={JSONData.services2Json.WhatWeDo.WhatWeDoRightImg.childImageSharp.fluid} 
                                        alt=""
                                    />
                                </WhatWeDoRightImgHolder>
                            </WhatWeDoRight>
                        </Col>

                        <Col md="6">
                            <WhatWeDoLeft>
                                <WhatWeDoLeftHeading>
                                {JSONData.services2Json.WhatWeDo.WhatWeDoLeftHeading}
                                </WhatWeDoLeftHeading>
                                <WhatWeDoLeftPara>
                                {JSONData.services2Json.WhatWeDo.WhatWeDoLeftPara1}
                                </WhatWeDoLeftPara>
                                <WhatWeDoLeftPara>
                                {JSONData.services2Json.WhatWeDo.WhatWeDoLeftPara2}
                                </WhatWeDoLeftPara>
                            </WhatWeDoLeft>
                        </Col>
                    </WhatWeDoRow>
                </WhatWeDoInner>
            </Container>
        </WhatWeDoSection>
    );
}
export default WhatWeDo;